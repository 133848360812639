@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');

.App {
  font-family: 'Open Sans', sans-serif;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* align-items: center;
  justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: white;
}

html {
  min-height: calc(100% + env(safe-area-inset-top)) !important;
}

body {
  position: fixed;
  overflow: hidden;
  width: 100vw;
}